<template>
  <div>
    <img :src="this.article.thumb" width="100%" height="100.001px" />
    <div class="title">
      {{ this.article.title }}
    </div>
    <van-row class="tip">
      <van-col span="8"> {{ this.article.name }} </van-col>
      <van-col span="6"
        ><span v-if="this.article.ishot == 1"> 热点推荐 </span></van-col
      >
      <van-col span="10">
        {{ this.article.atime }}
      </van-col>
    </van-row>
    <div
      class="description"
      v-if="this.article.keywords !== '' && this.article.keywords !== '-'"
    >
      关键字：{{ this.article.keywords }}
    </div>
    <div
      class="description"
      v-if="this.article.description !== '' && this.article.description !== '-'"
    >
      摘要：<br />
      {{ this.article.description }}
    </div>
    <van-row class="tip">
      <van-col span="9"
        ><van-icon name="manager" /> {{ this.article.views }} 人次</van-col
      >
      <van-col span="8"
        ><van-icon name="like" />{{ this.article.views }}</van-col
      >
      <van-col span="7"
        ><van-icon name="star" />{{ this.article.views }}</van-col
      >
    </van-row>
    <!-- <Outlink
      v-if="this.article.url !== '' && this.article !== '-'"
      :url="this.article.url"
    ></Outlink> -->
    <!-- <div
      v-if="this.article.url !== '' && this.article !== '-'"
      class="link"
      @click="toOutterlink(article.url)"
    >
      查看链接
    </div> -->
    <div
      class="content"
      v-html="this.article.content"
      v-if="
        this.article.content !== '' &&
        this.article.content !== '-' &&
        this.article.content !== '<p>-</p>'
      "
    ></div>
    <div
      class="content"
      v-html="this.article.getcontent"
      v-if="this.article.getcontent !== '' && this.article.getcontent !== '-'"
    ></div>
  </div>
</template>

<script>

// import Outlink from '@/components/article/Outlink'
export default {
  components: {
    // Outlink
  },
  //   props: ['article'],
  beforeMount () {
    // this.$store.commit('hideShowTabbar', false)
  },

  data () {
    return {
      //   back: 'skillful'
      article: ''
    }
  },
  mounted () {
    console.log(this.article, 'articleget.vue')
    this.article = JSON.parse(localStorage.getItem('article'))
    console.log(this.article, 'article.vue')
  },
  methods: {
    // toOutterlink (url) {
    //   console.log(url)

    //   window.location.href = url
    //   // this.$router.push(
    //   //   {
    //   //     name: 'LinkHome',
    //   //     params: {
    //   //       url: url,
    //   //       rname: rname
    //   //     }
    //   //   }
    //   // )
    // }
  },
  computed: {
    // articleget () {
    //   let article = this.$store.state.article.article
    //   if (article === null) {
    //     article = []
    //   }

    //   //   console.log(article, 'article')
    //   if (article !== []) {
    //     return article
    //   } else {
    //     article = localStorage.getItem('article')
    //   }
    //   var articleArr = []
    //   if (article !== null) {
    //     articleArr = JSON.parse(article)
    //   }
    //   return articleArr// .article
    // }
  }

}
</script>
<style lang="scss" scoped>
li,
div,
img {
  margin: 0vw;
  padding: 0vw;
}

.title {
  font-size: 4.167vw;
  padding-left: 2.778vw;
  padding-top: 2.778vw;
  padding-bottom: 2.778vw;
  color: rgb(45, 54, 56);
  font-size: 3.611vw;
}
.tip {
  font-size: 3.333vw;
  padding: 1.389vw;
  padding-top: 2.778vw;
  padding-left: 5.556vw;
  color: #aaa;
}
.description {
  color: rgb(148, 135, 135);
  font-size: 3.611vw;
  padding: 4.167vw;
  padding-bottom: 0vw;
  //   height: 50px;
  background-color: rgb(250, 250, 250);
  //   overflow: hidden;
  line-height: 20px;
}
.link {
  padding: 4.167vw;
  font-size: 3.611vw;
  line-height: 20px;
  color: coral;
  text-align: center;
}
.content {
  padding: 4.167vw;
  line-height: 20px;
  font-size: 3.333vw;
}
</style>
