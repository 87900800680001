<template>
  <div v-if="article">
    <NavbarCommon :title="article.title" :back="back"></NavbarCommon>
    <Article :article="article"></Article>
  </div>
  <van-loading v-else size="24px" color="#0094ff" vertical class="margintop"
    >资源加载中...</van-loading
  >
</template>

<script>
import NavbarCommon from '@/components/common/NavbarCommon'
import Article from '@/components/article/Article'
export default {
  components: {
    NavbarCommon, Article
  },
  //   props: ['cat'],
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  },

  data () {
    return {
      back: 'skillfull'
      //   article: ''
    }
  },
  mounted () {
    // console.log(this.categoryID, 'View  categoryID.vue')
    // console.log(this.Article, 'View  Article.vue')
    // this.article = JSON.parse(localStorage.getItem('article'))
    // var id = localStorage.getItem('articleId')
    // if (this.article === undefined || (typeof this.article === 'object' && this.article.id !== id)) {
    //   this.$store.commit('unsetArticleInfo', '')
    //   localStorage.setItem('article', '')
    //   // this.$store.commit('unsetPointslist', '')
    //   console.log('清除 Article')
    //   this.$store.dispatch('getArticleInfo', {
    //     articleid: id
    //   })
    //   //   console.log(this.$store.state.article.catlist, 'state catlist')
    //   console.log('重新请求 Article')
    // }
    // // id = ''

    console.log(this.article, 'article info')
  },
  methods: {
    // toCatid (catid) {
    //   console.log(catid)
    // }

  },
  computed: {
    article () {
      let article = this.$store.state.article.article
      if (article === null) {
        article = []
      }
      //   console.log(article, 'article')
      if (article !== []) {
        return article
      } else {
        article = localStorage.getItem('article')
      }
      var articleArr = []
      if (article !== null) {
        articleArr = JSON.parse(article)
      }
      return articleArr// .article
    }
    // categoryID () {
    //   let categoryID = this.$store.state.article.catid
    //   if (categoryID === null) {
    //     categoryID = []
    //   }
    //   //   console.log(categoryID, 'dddd')
    //   if (categoryID !== []) {
    //     return categoryID
    //   } else {
    //     categoryID = localStorage.getItem('categoryID')
    //   }
    //   var categoryIDArr = []
    //   if (categoryID !== null) {
    //     categoryIDArr = JSON.parse(categoryID)
    //   }
    //   return categoryIDArr// .courselist
    // }
  }

}
</script>
 <style lang="scss" scoped>
// li,
// div,
// img {
//   margin: 0vw;
//   padding: 0vw;
// }
// .catlist {
//   margin: 0vw;
//   //   padding: 5px;
//   //   margin-bottom: -2.778vw;
//   //   background-color: aqua;
//   //   overflow: hidden;
//   position: relative;
//   float: left;
//   //   clear: both;
//   width: 50%;
//   img {
//     width: 96%;
//     // width="100%"
//     height: 27.778vw;
//     padding-top: 1.389vw;
//   }
//   //   background-color: black;
//   .catlist-top {
//     position: absolute;
//     // background-color: aquamarine;
//     top: 2.778vw;
//   }
//   .catlist-bottom {
//     position: absolute;
//     bottom: 0vw;
//     height: 11.111vw;
//     padding-left: 2.778vw;
//     width: 50%;
//     .catlist-title {
//       color: rgb(100, 9, 9);
//       font-size: 3.611vw;
//       font-weight: bold;
//       padding: 0.556vw;
//     }
//     .van-card__title {
//       font-size: 3.056vw;
//       color: rgb(97, 14, 14);
//       padding-left: 5.556vw;
//       padding-top: 1.389vw;
//     }
//   }
// }
//
.margintop {
  margin-top: 5.556vw;
}
</style>
